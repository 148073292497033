import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Loader, ThemedSection } from "../../components";
import { useAuth } from "../../context/auth/useAuth";
import { formatLocale } from "../../lib";
import { PageContext } from "../../types";
import { RedirectionLoaderContainer } from "./styles/redirectionPage.styles";

interface DefaultPageProps {
  pageContext: PageContext;
}

const RedirectionPage = ({
  pageContext: { formattedLocale = "en", usableLocales }
}: DefaultPageProps) => {
  const auth = useAuth();

  useEffect(() => {
    if (formattedLocale) {
      const defaultUserLanguage = formatLocale(
        process.env.GATSBY_DEFAULT_LANGUAGE ?? formattedLocale
      );

      if (auth?.authState?.isAuthenticated) {
        const dashboardPath = `/${defaultUserLanguage}/dashboard`;
        navigate(dashboardPath);
      }

      const signInPath = `/${defaultUserLanguage}/signIn`;

      navigate(signInPath);
    }
  }, [auth?.authState?.isAuthenticated, formattedLocale, usableLocales]);

  return (
    <ThemedSection>
      <RedirectionLoaderContainer>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="description"
            content="A digital platform giving Vodafone customers the ability to manage their security services, learn and buy with a seamless customer experience."
          />
          <meta name="robots" content="noindex" />
          <title>CyberHub - Dashboard</title>
        </Helmet>

        <Loader
          text={{
            text:
              "CyberHub " +
              process.env.GATSBY_DEFAULT_LANGUAGE?.toLocaleUpperCase()
          }}
        />
      </RedirectionLoaderContainer>
    </ThemedSection>
  );
};

export default RedirectionPage;
