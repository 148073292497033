import styled from "styled-components";

export const RedirectionLoaderContainer = styled.main(
  () => `
  min-height: calc(100vh - 100px);
  height: 100%;  
  display:flex;
  justify-content:center;
  align-items:center;
`
);
